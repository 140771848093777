import { Card, Col, Container, Row, Button } from "react-bootstrap";
import { BaixarRelatorioTodasSolicitacoesRequest, BaixarRelatorioTodasAutorizacoesRequest, 
    BaixarRelatorioTodosSubitensRequest, BaixarRelatorioTodasMedicoesRequest,
    BaixarRelatorioStatusSolicitacaoRequest, BaixarRelatorioTodosSubitensTroRequest } from "../../../store/modules/relatorio/actions";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Header from "../../../components/Header/header";
import './relatorio.css';
import { SiMicrosoftexcel } from "react-icons/si";
import LogoRelatorioSolicitacao from '../../../assets/relatorio_solicitacoes.png';
import LogoRelatorioAutorizacao from '../../../assets/relatorio_autorizacoes.png';
import LogoRelatorioSubitens from '../../../assets/relatorio_subitens.png';
import ModalFiltroRelatorio from "../../../components/ModalFiltroRelatorio/modalFiltroRelatorio";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LogoRelatorioMedicao from '../../../assets/relatorio_medicoes.png';

export default function Relatorio() {
 
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [relatorioSelecionado, setRelatorioSelecionado] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Permissão de Visualizar
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const history = useHistory();
    useEffect(() => {
        if (token.role.filter(r => r == "RelatorioMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
    }, [])    
    
    function BaixarRelatorioTodasSolicitacoes(dataSolicitacao) {
        dispatch(BaixarRelatorioTodasSolicitacoesRequest(dataSolicitacao));   
    }

    function BaixarRelatorioTodasMedicoes(dataMedicao) { 
        dispatch(BaixarRelatorioTodasMedicoesRequest(dataMedicao));
    }

    function BaixarRelatorioTodasAutorizacoes(dataAutorizacao) { 
        dispatch(BaixarRelatorioTodasAutorizacoesRequest(dataAutorizacao));
    }

    function BaixarRelatorioTodosSubitens(idContrato) {
        dispatch(BaixarRelatorioTodosSubitensRequest(idContrato));
    }

    function BaixarRelatorioTodosSubitensTro(dataEmpenho) {

        if(!isNaN(dataEmpenho.IdContrato) && !isNaN(dataEmpenho.IdEmpenho) && dataEmpenho.IdContrato && dataEmpenho.IdEmpenho){
            dispatch(BaixarRelatorioTodosSubitensTroRequest(dataEmpenho));
        }
        else{
            toast.error('Preencha todos os campos');
        }
        
    }

    function clickRelatorio(metodoDispatch) {
        setRelatorioSelecionado(metodoDispatch);
        handleShow();
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col lg={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <SiMicrosoftexcel size={25} color={"#000"} /> Relatórios
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                <Row>
                                    <Col md={4}>
                                        <img className="imgRelatorio" src={LogoRelatorioSubitens} />
                                        <a className="aRelatorio" onClick={() => clickRelatorio('BaixarRelatorioTodosSubitens')} >Relatório de todos os Subitens</a>
                                    </Col>
                                    <Col md={4}>
                                        <img className="imgRelatorio" src={LogoRelatorioSolicitacao}  /> 
                                        <a className="aRelatorio" onClick={() => clickRelatorio('BaixarRelatorioTodasSolicitacoes')} >Relatório de todas as Solicitações</a>
                                    </Col>
                                    <Col md={4}>
                                        <img className="imgRelatorio" src={LogoRelatorioAutorizacao} />
                                        <a className="aRelatorio" onClick={() => clickRelatorio('BaixarRelatorioTodasAutorizacoes')} >Relatório de todas as Autorizações</a>
                                    </Col>
                                </Row><br/>
                                <Row>
                                    <Col md={4}>
                                        <img className="imgRelatorio" src={LogoRelatorioMedicao} />
                                        <a className="aRelatorio" onClick={() => clickRelatorio('BaixarRelatorioTodasMedicoes')} >Relatório de todas as Medições</a>
                                    </Col>
                                    <Col md={4}>
                                        <img className="imgRelatorio" src={LogoRelatorioSubitens} />
                                        <a className="aRelatorio" onClick={() => clickRelatorio('BaixarRelatorioTodosSubitensTro')} >Relatório de subitens - TRO</a>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            <ModalFiltroRelatorio
                show={show}
                onHide={handleClose}
                BaixarRelatorioTodasSolicitacoes={BaixarRelatorioTodasSolicitacoes}
                BaixarRelatorioTodasAutorizacoes={BaixarRelatorioTodasAutorizacoes}
                BaixarRelatorioTodasMedicoes={BaixarRelatorioTodasMedicoes}
                BaixarRelatorioTodosSubitens={BaixarRelatorioTodosSubitens}
                BaixarRelatorioTodosSubitensTro={BaixarRelatorioTodosSubitensTro}
                RelatorioSelecionado={ relatorioSelecionado }
            />
        </>
    );
}