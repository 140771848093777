export function ListarRelatorioAutorizacaoFornecimentoSubItemCustoRequest(idAutorizacaoFornecimento) {
  return {
    type: 'LISTAR_RELATORIO_AUTORIZACAO_FORNECIMENTO_SUBITEM_REQUEST',
    idAutorizacaoFornecimento
  }
}

export function ListarRelatorioAutorizacaoFornecimentoSubItemCustoResponse(listaRelatorioAutorizacaoFornecimentoSubitemCusto) {
  return {
    type: 'LISTAR_RELATORIO_AUTORIZACAO_FORNECIMENTO_SUBITEM_RESPONSE',
    listaRelatorioAutorizacaoFornecimentoSubitemCusto
  }
}

export function BaixarRelatorioTodasSolicitacoesRequest(data) {
  return {
    type: 'BAIXAR_RELATORIO_TODAS_SOLICITACOES_REQUEST',
    data
  }
}

export function BaixarRelatorioTodasMedicoesRequest(data) {
  return {
    type: 'BAIXAR_RELATORIO_TODAS_MEDICOES_REQUEST',
    data
  }
}

export function BaixarRelatorioTodasAutorizacoesRequest(data) {
  return {
    type: 'BAIXAR_RELATORIO_TODAS_AUTORIZACOES_REQUEST',
    data
  }
}

export function BaixarRelatorioTodosSubitensRequest(idContrato) {
  return {
    type: 'BAIXAR_RELATORIO_TODOS_SUBITENS_REQUEST',
    idContrato
  }
}

export function ListarRelatorioMedicaoSubItemRequest(data) {
  return {
    type: 'LISTAR_RELATORIO_MEDICAO_SUBITEM_REQUEST',
    data
  }
}

export function ListarRelatorioMedicaoSubItemResponse(listaRelatorioMedicaoSubItem) {
  return {
    type: 'LISTAR_RELATORIO_MEDICAO_SUBITEM_RESPONSE',
    listaRelatorioMedicaoSubItem
  }
}

export function ListarDadosExportarPDFAutorizacaoFornecimentoRequest(data) {
  return {
    type: 'LISTAR_DADOS_EXPORTAR_PDF_AUTORIZACAO_FORNECIMENTO_REQUEST',
    data
  }
}

export function ListarDadosExportarPDFAutorizacaoFornecimentoResponse(listaDadosExportarPDFAutorizacaoFornecimento) {
  return {
    type: 'LISTAR_DADOS_EXPORTAR_PDF_AUTORIZACAO_FORNECIMENTO_RESPONSE',
    listaDadosExportarPDFAutorizacaoFornecimento
  }
}

export function ListarDadosExportarPDFSolicitacaoFornecimentoRequest(data) {
  return {
    type: 'LISTAR_DADOS_EXPORTAR_PDF_SOLICITACAO_FORNECIMENTO_REQUEST',
    data
  }
}

export function ListarDadosExportarPDFSolicitacaoFornecimentoEventoResponse(listaDadosExportarPDFSolicitacaoFornecimentoEvento) {
  return {
    type: 'LISTAR_DADOS_EXPORTAR_PDF_SOLICITACAO_FORNECIMENTO_EVENTO_RESPONSE',
    listaDadosExportarPDFSolicitacaoFornecimentoEvento
  }
}

export function ListarDadosExportarPDFSolicitacaoFornecimentoEventoRequest(data) {
  return {
    type: 'LISTAR_DADOS_EXPORTAR_PDF_SOLICITACAO_FORNECIMENTO_EVENTO_REQUEST',
    data
  }
}

export function ListarDadosExportarPDFSolicitacaoFornecimentoResponse(listaDadosExportarPDFSolicitacaoFornecimento) {
  return {
    type: 'LISTAR_DADOS_EXPORTAR_PDF_SOLICITACAO_FORNECIMENTO_RESPONSE',
    listaDadosExportarPDFSolicitacaoFornecimento
  }
}

export function ListarDadosExportarPDFEmpenhoRequest(data) {
  return {
    type: 'LISTAR_DADOS_EXPORTAR_PDF_EMPENHO_REQUEST',
    data
  }
}

export function ListarDadosExportarPDFEmpenhoResponse(listaDadosExportarPDFEmpenho) {
  return {
    type: 'LISTAR_DADOS_EXPORTAR_PDF_EMPENHO_RESPONSE',
    listaDadosExportarPDFEmpenho
  }
}

export function ListarDadosExportarPDFEmpenhoBoletimMedicaoRequest(data) {
  return {
    type: 'LISTAR_DADOS_EXPORTAR_PDF_EMPENHO_BOLETIM_MEDICAO_REQUEST',
    data
  }
}

export function ListarDadosExportarPDFEmpenhoBoletimMedicaoResponse(listaDadosExportarPDFEmpenhoBoletimMedicao) {
  return {
    type: 'LISTAR_DADOS_EXPORTAR_PDF_EMPENHO_BOLETIM_MEDICAO_RESPONSE',
    listaDadosExportarPDFEmpenhoBoletimMedicao
  }
}


export function ListarTodasFotosCompletasAutorizacaoFornecimentoRequest(idEmpenho) {
  return {
    type: 'LISTAR_TODAS_FOTOS_COMPLETAS_AUTORIZACAO_FORNECIMENTO_REQUEST',
    idEmpenho
  }
}

export function RelatorioEventoRespostaRequest(data) {
  return {
    type: 'RELATORIO_EVENTO_RESPOSTA_REQUEST',
    data
  }
}

export function RelatorioTodasMedicaoSubItemsRequest(idMedicaoAutorizacaoFornecimento) {
  return {
    type: 'RELATORIO_TODAS_MEDICAO_SUBITEMS_REQUEST',
    idMedicaoAutorizacaoFornecimento
  }
}

export function LimparRelatorioEmpenho() {
  return {
    type: 'LIMPAR_RELATORIO_EMPENHO'
  }
}

export function ListarRelatorioStatusSolicitacaoRequest(dataInicio, dataFinal, visualizarTudo) {
  return {
    type: 'LISTAR_RELATORIO_STATUS_SOLICITACAO_REQUEST',
    dataInicio, dataFinal, visualizarTudo
  }
}

export function ListarRelatorioStatusSolicitacaoResponse(listaRelatorioStatusSolicitacao) {
  return {
    type: 'LISTAR_RELATORIO_STATUS_SOLICITACAO_RESPONSE',
    listaRelatorioStatusSolicitacao
  }
}

export function ListarRelatorioStatusAutorizacaoRequest() {
  return {
    type: 'LISTAR_RELATORIO_STATUS_AUTORIZACAO_REQUEST'
  }
}

export function ListarRelatorioStatusAutorizacaoResponse(listaRelatorioStatusAutorizacao) {
  return {
    type: 'LISTAR_RELATORIO_STATUS_AUTORIZACAO_RESPONSE',
    listaRelatorioStatusAutorizacao
  }
}

export function ListarRelatorioStatusMedicaoRequest() {
  return {
    type: 'LISTAR_RELATORIO_STATUS_MEDICAO_REQUEST'
  }
}

export function ListarRelatorioStatusMedicaoResponse(listaRelatorioStatusMedicao) {
  return {
    type: 'LISTAR_RELATORIO_STATUS_MEDICAO_RESPONSE',
    listaRelatorioStatusMedicao
  }
}

export function ListarRelatorioStatusEmpenhoRequest() {
  return {
    type: 'LISTAR_RELATORIO_STATUS_EMPENHO_REQUEST'
  }
}

export function ListarRelatorioStatusEmpenhoResponse(listaRelatorioStatusEmpenho) {
  return {
    type: 'LISTAR_RELATORIO_STATUS_EMPENHO_RESPONSE',
    listaRelatorioStatusEmpenho
  }
}

export function ListarRelatorioSolicitacaoRequest() {
  return {
    type: 'LISTAR_RELATORIO_SOLICITACAO_REQUEST'
  }
}

export function ListarRelatorioSolicitacaoResponse(listaRelatorioSolicitacao) {
  return {
    type: 'LISTAR_RELATORIO_SOLICITACAO_RESPONSE',
    listaRelatorioSolicitacao
  }
}

export function BaixarRelatorioTodosSubitensTroRequest(model) {
  return {
    type: 'BAIXAR_RELATORIO_TODOS_SUBITENS_TRO_REQUEST',
    model
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}
