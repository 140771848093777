import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {
  ListarRelatorioMedicaoSubItemResponse,
  ListarRelatorioAutorizacaoFornecimentoSubItemCustoResponse,
  ListarDadosExportarPDFAutorizacaoFornecimentoResponse,
  ListarDadosExportarPDFSolicitacaoFornecimentoResponse,
  ListarDadosExportarPDFEmpenhoResponse,
  ListarTodasFotosCompletasAutorizacaoFornecimentoResponse,
  ListarDadosExportarPDFSolicitacaoFornecimentoEventoResponse,
  ListarDadosExportarPDFEmpenhoBoletimMedicaoResponse,
  ListarRelatorioStatusSolicitacaoResponse,
  ListarRelatorioStatusAutorizacaoResponse,
  ListarRelatorioStatusMedicaoResponse,
  ListarRelatorioStatusEmpenhoResponse,
  ListarRelatorioSolicitacaoResponse
} from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import { Carregando } from '../perfil/actions';
import urlBase from '../../../services/rotas';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import history from '../../../services/history';

function* BaixarRelatorioTodasSolicitacoesRequest(model) {

  try {

    const cookies = new Cookies();
    yield put(Carregando(false));
    yield put(AtualizarToken());

    fetch(`${urlBase}/api/Relatorio/BaixarRelatorioTodasSolicitacoes`, {
      method: "POST",
      headers: new Headers({
        'Authorization': JSON.parse(localStorage.getItem('token')) !== null ?
          `Bearer ${cookies.get('token')}${JSON.parse(localStorage.getItem('token')).tokenCodificado}`
          : '',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(model.data),
    })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Relatório de todas as Solicitações';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BaixarRelatorioTodasAutorizacoesRequest(model) {

  try {

    const cookies = new Cookies();
    yield put(Carregando(false));
    yield put(AtualizarToken());

    fetch(`${urlBase}/api/Relatorio/BaixarRelatorioTodasAutorizacoes`,
      {
        method: "POST",
        headers: new Headers({
          'Authorization': JSON.parse(localStorage.getItem('token')) !== null ?
            `Bearer ${cookies.get('token')}${JSON.parse(localStorage.getItem('token')).tokenCodificado}`
            : '',
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(model.data),
      })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Relatório de todas as Autorizações';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BaixarRelatorioTodasMedicoesRequest(model) {

  try {

    const cookies = new Cookies();
    yield put(Carregando(false));
    yield put(AtualizarToken());

    fetch(`${urlBase}/api/Relatorio/BaixarRelatorioTodasMedicoes`,
      {
        method: "POST",
        headers: new Headers({
          'Authorization': JSON.parse(localStorage.getItem('token')) !== null ?
            `Bearer ${cookies.get('token')}${JSON.parse(localStorage.getItem('token')).tokenCodificado}`
            : '',
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(model.data),
      })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Relatório de todas as Medições';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BaixarRelatorioTodosSubitensRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/Relatorio/BaixarRelatorioTodosSubitens/${model.idContrato}`, { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Relatório de todos os subitens do contrato ' + model.idContrato;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }


    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}


function* BaixarRelatorioTodosSubitensTroRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    const cookies = new Cookies();

    let data = {
      'IdContrato': parseInt(model.model.IdContrato),
      'IdEmpenho': parseInt(model.model.IdEmpenho)
  }

  fetch(`${urlBase}/api/Relatorio/BaixarRelatorioTodosSubitensTro`, {
    method: "POST",
    headers: new Headers({
      'Authorization': JSON.parse(localStorage.getItem('token')) !== null ?
        `Bearer ${cookies.get('token')}${JSON.parse(localStorage.getItem('token')).tokenCodificado}`
        : '',
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(data),
  })
    .then(response => response.blob())
    .then(function (excel) {
      let elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(excel);
      elem.download = 'Relatório de todos os subitens do TRO ' + model.model.IdEmpenho;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    });
  yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }


    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}


function* ListarRelatorioStatusSolicitacaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    let data = {
      dataInicio: model.dataInicio,
      dataFinal: model.dataFinal,
      visualizarTudo: model.visualizarTudo
    }

    const result = yield call(api.post, `/api/Relatorio/ListarRelatorioStatusSolicitacao`, data, 
    { headers: headerParams.token });

    yield put(ListarRelatorioStatusSolicitacaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarRelatorioStatusAutorizacaoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Relatorio/ListarRelatorioStatusAutorizacao`,
    { headers: headerParams.token });

    yield put(ListarRelatorioStatusAutorizacaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarRelatorioStatusMedicaoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Relatorio/ListarRelatorioStatusMedicao`,
    { headers: headerParams.token });

    yield put(ListarRelatorioStatusMedicaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarRelatorioStatusEmpenhoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Relatorio/ListarRelatorioStatusEmpenho`,
    { headers: headerParams.token });

    yield put(ListarRelatorioStatusEmpenhoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarRelatorioMedicaoSubItemRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/Relatorio/ListarRelatorioMedicaoSubItem`,
      model.data, { headers: headerParams.token });

    yield put(ListarRelatorioMedicaoSubItemResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarRelatorioAutorizacaoFornecimentoSubItemCustoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/Relatorio/ListarRelatorioAutorizacaoFornecimentoSubItemCusto`,
      { idAutorizacaoFornecimento: parseInt(model.idAutorizacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarRelatorioAutorizacaoFornecimentoSubItemCustoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarDadosExportarPDFAutorizacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/Relatorio/ListarDadosExportarPDFAutorizacaoFornecimento`,
      model.data, { headers: headerParams.token });

    yield put(ListarDadosExportarPDFAutorizacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarDadosExportarPDFSolicitacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/Relatorio/ListarDadosExportarPDFSolicitacaoFornecimento`,
      model.data, { headers: headerParams.token });

    yield put(ListarDadosExportarPDFSolicitacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarDadosExportarPDFEmpenhoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/Relatorio/ListarDadosExportarPDFEmpenho`,
      model.data, { headers: headerParams.token });

    yield put(ListarDadosExportarPDFEmpenhoResponse(result.data.result));
    yield put(Carregando(true));


  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarDadosExportarPDFEmpenhoBoletimMedicaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/Relatorio/ListarDadosExportarPDFEmpenhoBoletimMedicao`,
      model.data, { headers: headerParams.token });

    yield put(ListarDadosExportarPDFEmpenhoBoletimMedicaoResponse(result.data.result));
    yield put(Carregando(true));


  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarTodasFotosCompletasAutorizacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/Relatorio/${parseInt(model.idEmpenho)}/0/ListarTodasFotosCompletasAutorizacaoFornecimento`, { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (pdf) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(pdf);
        elem.download = 'RegistrosFotograficos.pdf'
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ListarDadosExportarPDFSolicitacaoFornecimentoEventoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/Relatorio/ListarDadosExportarPDFAutorizacaoFornecimentoEvento`,
      model.data, { headers: headerParams.token });

    yield put(ListarDadosExportarPDFSolicitacaoFornecimentoEventoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* RelatorioEventoRespostaRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/Relatorio/${model.data.IdSolicitacaoFornecimento}/${model.data.IdEventoResposta}/RelatorioEventoResposta`, { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (pdf) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(pdf);
        elem.download = 'relatorioEventoRspostas.pdf'
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }

}

function* RelatorioTodasMedicaoSubItemsRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/Relatorio/BaixarRelatorioTodasMedicaoSubItems/${model.idMedicaoAutorizacaoFornecimento}`, { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Relatório de todas os subitens da Medição';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }


    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarRelatorioSolicitacaoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Relatorio/ListarRelatorioSolicitacao`,
    { headers: headerParams.token });

    yield put(ListarRelatorioSolicitacaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

export default all([
  takeLatest('BAIXAR_RELATORIO_TODAS_SOLICITACOES_REQUEST', BaixarRelatorioTodasSolicitacoesRequest),
  takeLatest('BAIXAR_RELATORIO_TODAS_AUTORIZACOES_REQUEST', BaixarRelatorioTodasAutorizacoesRequest),
  takeLatest('BAIXAR_RELATORIO_TODAS_MEDICOES_REQUEST', BaixarRelatorioTodasMedicoesRequest),
  takeLatest('BAIXAR_RELATORIO_TODOS_SUBITENS_REQUEST', BaixarRelatorioTodosSubitensRequest),
  takeLatest('LISTAR_RELATORIO_MEDICAO_SUBITEM_REQUEST', ListarRelatorioMedicaoSubItemRequest),
  takeLatest('LISTAR_RELATORIO_AUTORIZACAO_FORNECIMENTO_SUBITEM_REQUEST', ListarRelatorioAutorizacaoFornecimentoSubItemCustoRequest),
  takeLatest('LISTAR_DADOS_EXPORTAR_PDF_AUTORIZACAO_FORNECIMENTO_REQUEST', ListarDadosExportarPDFAutorizacaoFornecimentoRequest),
  takeLatest('LISTAR_DADOS_EXPORTAR_PDF_SOLICITACAO_FORNECIMENTO_REQUEST', ListarDadosExportarPDFSolicitacaoFornecimentoRequest),
  takeLatest('LISTAR_DADOS_EXPORTAR_PDF_EMPENHO_REQUEST', ListarDadosExportarPDFEmpenhoRequest),
  takeLatest('LISTAR_TODAS_FOTOS_COMPLETAS_AUTORIZACAO_FORNECIMENTO_REQUEST', ListarTodasFotosCompletasAutorizacaoFornecimentoRequest),
  takeLatest('LISTAR_DADOS_EXPORTAR_PDF_SOLICITACAO_FORNECIMENTO_EVENTO_REQUEST', ListarDadosExportarPDFSolicitacaoFornecimentoEventoRequest),
  takeLatest('LISTAR_DADOS_EXPORTAR_PDF_EMPENHO_BOLETIM_MEDICAO_REQUEST', ListarDadosExportarPDFEmpenhoBoletimMedicaoRequest),
  takeLatest('RELATORIO_EVENTO_RESPOSTA_REQUEST', RelatorioEventoRespostaRequest),
  takeLatest('RELATORIO_TODAS_MEDICAO_SUBITEMS_REQUEST', RelatorioTodasMedicaoSubItemsRequest),
  takeLatest('LISTAR_RELATORIO_STATUS_SOLICITACAO_REQUEST', ListarRelatorioStatusSolicitacaoRequest),
  takeLatest('LISTAR_RELATORIO_STATUS_AUTORIZACAO_REQUEST', ListarRelatorioStatusAutorizacaoRequest),
  takeLatest('LISTAR_RELATORIO_STATUS_MEDICAO_REQUEST', ListarRelatorioStatusMedicaoRequest),
  takeLatest('LISTAR_RELATORIO_STATUS_EMPENHO_REQUEST', ListarRelatorioStatusEmpenhoRequest),
  takeLatest('LISTAR_RELATORIO_SOLICITACAO_REQUEST', ListarRelatorioSolicitacaoRequest),
  takeLatest('BAIXAR_RELATORIO_TODOS_SUBITENS_TRO_REQUEST', BaixarRelatorioTodosSubitensTroRequest),
])